<template>
  <div class="app-container merch-card">
    <el-form ref="queryForm" :model="queryParams" :inline="true">
      <el-form-item>
        <el-select
          clearable
          v-model="queryParams.status"
          placeholder="请选择状态类型"
        >
          <el-option
            v-for="item in statusType"
            :key="item.code"
            :label="item.name"
            :value="item.code"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-input
          v-model="queryParams.name"
          placeholder="请输入名称"
          clearable
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="handleQuery" style="width: 80px"
          >搜索</el-button
        >
        <el-button @click="openAddDialog" style="width: 80px" plain
          >新建</el-button
        >
      </el-form-item>
    </el-form>
    <!-- 列表 -->
    <div
      class="merch-table scorr-roll"
      :style="`max-height: ` + mainHright + `px`"
    >
      <div>
        <el-table
          border
          :data="searchData"
          style="width: 100%"
          v-loading="loading"
          :header-cell-style="{ background: '#F2F3F5' }"
          :default-sort="{ prop: 'serialNumber', order: 'descending' }"
          @sort-change="sort"
        >
          <el-table-column align="center" prop="name" label="页面名称"></el-table-column>
          <el-table-column
            align="center"
            label="状态"
          >
          <template slot-scope="{ row }">
            <el-switch
              v-model="row.status"
              :active-value="1"
              :inactive-value="0"
              active-color="#13ce66"
              inactive-color="#ff4949"
              @change="changeSwitch(row)"
            />
          </template>
          </el-table-column>
          <el-table-column
            prop="sort"
            label="排序"
            align="center"
            :sortable="'custom'"
          >
            <template slot-scope="scope">
              <el-input
                @input="
                  scope.row.sort = scope.row.sort.replace(
                    /^(0+)|[^\d]+/g,
                    ''
                  )
                "
                v-model="scope.row.sort"
                style="width: 80px"
                @blur="Changesorting(scope.row)"
                @keyup.enter.native="Changesorting(scope.row)"
              />
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            label="app-url"
            prop="appUrl"
          ></el-table-column>
          <el-table-column
            align="center"
            label="小程序-url"
            prop="miniProgramUrl"
          ></el-table-column>
          <el-table-column
            align="center"
            label="pcUrl"
            prop="pcUrl"
          ></el-table-column>
          <el-table-column label="操作" align="center" width="300">
            <template slot-scope="scope">
              <span class="btsed" @click="handleCompile(scope.row)">编辑</span>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <!-- 分页 -->
    <div class="merch-page">
      <el-pagination
        :current-page="current_page"
        background
        @current-change="handleCurrentChange"
        layout="total, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
    <el-dialog :title="isAddorEdit ? '新建' : '编辑'" :visible.sync="addDialog" width="30%">
      <el-form
        ref="compileForm"
        :model="compileForm"
        :rules="rules"
        label-width="110px"
      >
        <el-form-item label="名称：" prop="name">
          <el-input
            v-model="compileForm.name"
            placeholder="请输入名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="app-url：">
          <el-input
            v-model="compileForm.appUrl"
            placeholder="请输入app-url"
          ></el-input>
        </el-form-item>
        <el-form-item label="小程序-url：">
          <el-input
            v-model="compileForm.miniProgramUrl"
            placeholder="请输入小程序-url"
          ></el-input>
        </el-form-item>
        <el-form-item label="pc-url：">
          <el-input
            v-model="compileForm.pcUrl"
            placeholder="请输入pc-url"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addDialog = false">取 消</el-button>
        <el-button type="primary" @click="handleAdd" :loading="buttonLoading"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
  
<script>
import { createNamespacedHelpers } from "vuex";
const commonIndex = createNamespacedHelpers("commonIndex"); //vuex公共库
const { mapState, mapActions } = createNamespacedHelpers("Basics"); //vuex公共库
export default {
  data() {
    return {
      isAddorEdit: true,
      buttonLoading:false,
      addDialog: false,
      // 加载状态
      loading: false,
      // 总条数
      total: 0,
      current_page:null,
      queryParams: {
        page: 1,
        perPage:10,
        status: null,
        name: "",
        sortType:0,
        sort:null
      },
      statusType: [
        {
          name: "关闭",
          code: 0,
        },
        {
          name: "开启",
          code: 1,
        },
      ],
      compileForm: {
        name:'',
        pcUrl:'',
        appUrl:'',
        miniProgramUrl:'',
      },
      searchData: [],
      rules: {
        name: [
          { required: true, message: "页面名称必填", trigger: "change" },
        ],
      },
    };
  },
  computed: {
    ...commonIndex.mapState(["allJurisdiction", "detailsSeach"]),
  },
  mounted() {
    this.handleQuery();
  },
  methods: {
    ...mapActions([
      "systemPageList",
      "changeStatus",
      "changeSort",
      "systemPageDetail",
      "systemPageAdd",
      "systemPageUpdate"
    ]),
    ...commonIndex.mapState(["mainHright"]),
    openAddDialog(){
      if (this.$refs.compileForm !== undefined) this.$refs.compileForm.resetFields()
      this.addDialog = true
      this.isAddorEdit = true
      this.compileForm = {
        name:'',
        pcUrl:'',
        appUrl:'',
        miniProgramUrl:'',
      }
    },
    async handleCompile(row) {
      let data = await this.systemPageDetail({id: row.id})
      if(data.code == 200){
        if (this.$refs.compileForm !== undefined) this.$refs.compileForm.resetFields()
        this.addDialog = true
        this.isAddorEdit = false
        Object.assign(this.compileForm,data.data)
        this.compileForm.id = row.id
      }
    },
    async changeSwitch(row){
      let res = await this.changeStatus({id: row.id,status: row.status})
      if(res.code == 200){
        this.$message({
          message: res.message,
          type: "success",
        });
      }
      this.getList();
    },
    sort(data) {
      this.queryParams.sort = data.order === "ascending" ? 1 : data.order === "descending" ? 2 : "";
      this.queryParams.sortType = 1
      this.getList();
    },
    async Changesorting(row) {
      let res = await this.changeSort({id:row.id,sort:row.sort})
      if(res.code == 200){
        this.$message({
          message: res.message,
          type: "success",
        });
        this.getList();
      }
    },
    
    async handleAdd(){
      this.$refs.compileForm.validate(async (valid) =>{
          if(valid){
            if(this.isAddorEdit){
              let res = await this.systemPageAdd(this.compileForm)
              if(res.code == 200){
                this.addDialog = false
                this.$message({
                  message: '新增成功',
                  type: "success",
                });
              }
            }else{
              let data = await this.systemPageUpdate(this.compileForm)
              if(data.code == 200){
                this.addDialog = false
                this.$message({
                  message: '保存成功',
                  type: "success",
                });
              }
            }
            this.queryParams.sortType = 0
            this.queryParams.sort = null
            this.getList();
          }
      })
    },
    handleCurrentChange(e) {
      if (e != undefined) {
        this.queryParams.page = e;
      }
      this.getList();
    },
    async getList() {
      this.loading = true;
      let res = await this.systemPageList(this.queryParams);
      this.loading = false;
      this.searchData = res.data.data;
      this.total = res.data.total;
      this.current_page = res.data.current_page
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.page = 1;
      this.getList();
    },
  },
};
</script>
  <style lang="scss" scoped>
.app-container {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
}
.el-form-item {
  margin-bottom: 30px;
}
.merch-table {
  margin-top: 1%;
  width: 100%;
  height: 77%;
}
.merch-page {
  margin-top: 10px;
  width: 100%;
  display: flex;
  align-content: center;
  justify-content: right;
}
::v-deep .el-input--small .el-input__inner {
  height: 40px;
  line-height: 40px;
}
::v-deep .el-form--inline .el-form-item {
  margin-right: 2%;
  margin-top: 1%;
}
</style>
  